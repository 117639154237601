



















































































































@import "../../less/vars.less";
@import "../../less/mixins.less";

.mhPagination {
	//outline: 1px solid fade(red, 20);
	display: flex;
	font-weight: bold;

	@item : {
		height: 2.5em;
	}

	&__inner {
		display: flex;
	}

	&__numbers {
		display: flex;
	}
	&__link {
		transition: opacity 0.2s ease;
		position: relative;
		display: block;
		//padding: 0.5em 0.8em;
		opacity: 0.35;
		color: inherit;
		margin-right: -1px;
	}
	&__link--arrow,
	&__link--number {
		height: @item[height];
		width: @item[height];
		line-height: @item[height];
		text-align: center;
	}
	&__link::after {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		border: 1px solid;
		content: "";
	}
	&__link:not(:last-child):not(:hover):not(&__link--isActive)::after {
		border-right: none;
	}
	&__link--isActive {
		opacity: 1;
		&::after { border-width: 2px; }
	}
	&__link:not(&__link--isActive):hover {
		opacity: 0.75;
	}

	// hide pagination if there's just one page
	&--countPages-1 {
		display: none;
	}

	// context specific
	&:not(&--shop) {
		.mhPagination__link--isActive {
			background-color: black;
			span { color: white; }
		}
	}
	&--shop {
		.mhPagination__link--isActive {
			background-color: white;
			span { color: black; }
		}
	}

}
