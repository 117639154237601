.mhPagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
}
.mhPagination__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mhPagination__numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mhPagination__link {
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  position: relative;
  display: block;
  opacity: 0.35;
  color: inherit;
  margin-right: -1px;
}
.mhPagination__link--arrow,
.mhPagination__link--number {
  height: 2.5em;
  width: 2.5em;
  line-height: 2.5em;
  text-align: center;
}
.mhPagination__link::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid;
  content: "";
}
.mhPagination__link:not(:last-child):not(:hover):not(.mhPagination__link--isActive)::after {
  border-right: none;
}
.mhPagination__link--isActive {
  opacity: 1;
}
.mhPagination__link--isActive::after {
  border-width: 2px;
}
.mhPagination__link:not(.mhPagination__link--isActive):hover {
  opacity: 0.75;
}
.mhPagination--countPages-1 {
  display: none;
}
.mhPagination:not(.mhPagination--shop) .mhPagination__link--isActive {
  background-color: black;
}
.mhPagination:not(.mhPagination--shop) .mhPagination__link--isActive span {
  color: white;
}
.mhPagination--shop .mhPagination__link--isActive {
  background-color: white;
}
.mhPagination--shop .mhPagination__link--isActive span {
  color: black;
}



/*# sourceMappingURL=EventsView.92e084b1.css.map*/